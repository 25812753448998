import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import MenuBar from '../components/menu-bar';
import getComponent from '../components';

import layoutStyle from './layout.module.scss';

const Layout = ({ pageContext: { headData, components, footer = false }, location}) => {
  const { title = '', metas = [] } = headData;
  const hasHero = components.some(component => component.componentName === 'Hero');
  const [activeBurgerMenu, setActiveBurgerMenu] = useState(false);

  return (
    <>
      <Helmet>
        <title lang="en">{title}</title>
        {metas.map(({name, content}) => {
          return <meta key={name} name={name} content={content} />
          })
        }
        <link rel="stylesheet" type="text/css" href="https://use.typekit.net/jdw4wno.css" />
      </Helmet>
      <section className={`${layoutStyle.main} ${activeBurgerMenu ? layoutStyle.mainFixed : ''}`}>
        <MenuBar setActiveBurgerMenu={setActiveBurgerMenu} activeBurgerMenu={activeBurgerMenu} hasHero={hasHero} location={location} />
        {components.map(({componentName, props}, index) => {
          return getComponent(componentName, props, index);
        })}
        {footer &&
          getComponent(footer.componentName, footer.props, components.length)
        }
      </section>
    </>
  )
}

export default Layout