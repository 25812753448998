import React from 'react';

import plainTextStyles from './plain-text.module.scss';

function PlainText({ text, videoBelow = false }) {
  return (
    <p
      className={`${plainTextStyles.plainText} ${videoBelow ?plainTextStyles.plainTextVideoBelow : ''}`}
      dangerouslySetInnerHTML={{
        __html: text
      }}
    />
  )
}

export default PlainText;