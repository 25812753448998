import React from 'react';

import pageIntroStyles from './page-intro.module.scss';

function PageIntro({ heading, subHeading, content }) {
  return (
    <div className={pageIntroStyles.pageIntro}>
      <h2 className={pageIntroStyles.pageIntro__heading}>{heading}</h2>
      {subHeading &&
        <h4
          className={pageIntroStyles.pageIntro__subHeading}
          dangerouslySetInnerHTML={{
            __html: subHeading
          }}
        />
      }
      <p
        className={pageIntroStyles.pageIntro__text}
        dangerouslySetInnerHTML={{
          __html: content
        }}
      >
      </p>
    </div>
  )
}

export default PageIntro;