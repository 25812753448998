import React from 'react';

import singleImageStyles from './single-image.module.scss';

function SingleImage({ imagePathMobile, imagePathDesktop, altText}) {
  return (
    <picture>
      <source
        media="(max-width: 320px)"
        srcSet={require(`../../images${imagePathMobile}`)}
        size="100vw"
      />
      <img
        srcSet={require(`../../images${imagePathDesktop}`)}
        alt={altText}
        className={singleImageStyles.singleImage__image}
      />
    </picture>
  )
}

export default SingleImage;