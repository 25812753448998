import React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import navLinks from '../../data/nav/nav.json';
import logo from './../../images/logo/logo.svg';

import menuBarStyle from './menu-bar.module.scss';

function MenuBar({ hasHero, setActiveBurgerMenu, activeBurgerMenu, location }) {

  return (
    <div className={`${menuBarStyle.menuBar} ${hasHero ? '' : menuBarStyle.menuBarRelative}`}>
      <GatsbyLink to={'/'} className={menuBarStyle.menuBar__logo}><img src={logo} alt="Logo" className={menuBarStyle.menuBar__logo} /></GatsbyLink>
      <input type="checkbox" className={menuBarStyle.menuBar__mobileToggle} onClick={() => {
        setActiveBurgerMenu(!activeBurgerMenu)
      }} />
      
      <div className={menuBarStyle.menuBar__hamburger}>
        <div />
      </div>
      <nav className={menuBarStyle.menuBar__links}>
        {
          navLinks.map((link) => {
            if (location.pathname === '/' && link.link === '/') return '';
            return (
              <GatsbyLink
                key={link.text}
                to={`/${link.link}`}>
                  {link.text} <span className={`${menuBarStyle.chevron} ${menuBarStyle.right}`} />
              </GatsbyLink>
            )
          })
        }
      </nav>
    </div>
  )
}

export default MenuBar;