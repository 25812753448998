import React from 'react';

import headerWithSubtitleStyles from './header-with-subtitle.module.scss';

function HeaderWithSubtitle({ id = false, header, subtitle }) {
  return (
    <div id={id ? id : undefined} className={headerWithSubtitleStyles.textBox}>
      <h3>{header}</h3>
      <p className={headerWithSubtitleStyles.textBox__subtitle}>{subtitle}</p>
    </div>
  )
}

export default HeaderWithSubtitle;