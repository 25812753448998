import React from 'react';

import heroStyle from './hero.module.scss';

function Hero({ text = false }) {
  return (
    <>
      <div className={heroStyle.heroBackground}>
        {text && 
          <div className={heroStyle.heroBackground__textBox}>
            <h2>{text.title}</h2>
            <p
              className={heroStyle.heroBackground__textBox__subtitle}
              dangerouslySetInnerHTML={{
                __html: text.subtitle
              }}
            />
          </div>
        }
      </div>
      {text && 
        <div className={`${heroStyle.heroBackground__textBox} ${heroStyle.heroBackground__textBoxShowMobile}`}>
          <h2>{text.title}</h2>
          <p
            className={heroStyle.heroBackground__textBox__subtitle}
            dangerouslySetInnerHTML={{
              __html: text.subtitle
            }}
          />
        </div>
      }
    </>
    
  )
}
// ffc003

export default Hero;