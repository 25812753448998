import React, { useState } from 'react';
import { Helmet } from 'react-helmet';

import videoStyles from './video.module.scss';

function Video({ id, link, title, text, image: { imagePathDesktop, imagePathMobile, altText} }) {
  const [play, setPlay] = useState(false);

  return (
    <>
      <Helmet>
        <link rel="preload" href={link} as="document" />
      </Helmet>
      {!play &&
        <div className={videoStyles.video__imageContainer}
          onClick={() => setPlay(!play)}
        >
          <span className={videoStyles.video__imageContainer__playButton}></span>
          <picture>
            <source
              media="(max-width: 600px)"
              srcSet={require(`../../images${imagePathMobile}`)}
              size="100vw"
            />
            <img
              srcSet={require(`../../images${imagePathDesktop}`)}
              alt={altText}
              className={videoStyles.video__imageContainer__image}
            />
          </picture>
        </div>
      }
      <div className={`${videoStyles.video__player} ${play ? videoStyles.video__playerShow : ''}`}>
        <iframe
          id={id}
          title={title}
          src={`${link}/?autoplay=${play ? 1 : 0}`}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen
        />
      </div>
      {text &&
        <p
          className={videoStyles.video__text}
          dangerouslySetInnerHTML={{ __html: text }}
        />
      }
    </>
  )
}
export default Video;

// process.env.NODE_ENV !== 'production'