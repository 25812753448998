import React from 'react';
import { Link as GatsbyLink } from 'gatsby';

import linkStyles from './link.module.scss';

function Links({ links }) {
  return (
    <div className={linkStyles.links}>
      {links.map(({text, type, page}) => {
        return (
          <GatsbyLink key={page} to={page} className={linkStyles.links__link}>
            {type === 'back' && <i
              className={`${linkStyles.links__link__icon} ${linkStyles.links__link__iconLeft}`}
            />}
            {text}
            {type === 'forward' && <i
              className={`${linkStyles.links__link__icon} ${linkStyles.links__link__iconRight}`}
            />}
          </GatsbyLink>
        )
      })}
      
    </div>
  )
}

export default Links;