import React from 'react';
import Hero from './hero';
import ImageTiles from './image-tiles';
import HeaderWithSubtitle from './header-with-subtitle';
import OtherClients from './other-clients';
import Footer from './footer';
import HeaderWithText from './header-with-text';
import PageIntro from './page-intro';
import FullWidthBanner from './full-width-banner';
import DuleImage from './dule-image';
import SingleImage from './single-image';
import PlainText from './plain-text';
import Video from './video';
import Links from './links';

function getComponent(name, props, index) {
  switch (name) {
    case 'Hero':
      return <Hero key={index} {...props} />;
    case 'ImageTiles':
      return <ImageTiles key={index} {...props} />
    case 'HeaderWithSubtitle':
      return <HeaderWithSubtitle key={index} {...props} />
    case 'OtherClients':
      return <OtherClients key={index} {...props} />
    case 'Footer':
      return <Footer key={index} {...props} />
    case 'HeaderWithText':
      return <HeaderWithText key={index} {...props} />
    case 'PageIntro':
      return <PageIntro key={index} {...props} />
    case 'FullWidthBanner':
      return <FullWidthBanner key={index} {...props} />
    case 'DuleImage':
      return <DuleImage key={index} {...props} />
    case 'SingleImage':
      return <SingleImage key={index} {...props} />
    case 'PlainText':
      return <PlainText key={index} {...props} />
    case 'Video':
      return <Video key={index} {...props} />
    case 'Links':
      return <Links key={index} {...props} />
    default:
      return 'Component not found';
  }
}

export default getComponent;