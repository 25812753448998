import React from 'react';

import headerWithTextStyles from './header-with-text.module.scss';


function HeaderWithText({ heading, content }) {
  return (
    <div className={headerWithTextStyles.headerWithText}>
      {heading &&
        <h4
          dangerouslySetInnerHTML={{
            __html: heading
          }}
        />
      }
      <p
        className={headerWithTextStyles.headerWithText__text}
        dangerouslySetInnerHTML={{
          __html: content
        }}
      />
    </div>
  )
}

export default HeaderWithText;