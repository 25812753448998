import React from 'react';

import otherClientsStyles from './other-clients.module.scss';

function Footer({ textAboveImages, images }) {
  return (
    <>
      <div className={otherClientsStyles.otherClients}>
        <div className={otherClientsStyles.otherClients__contentContainer}>
          <h4 className={otherClientsStyles.otherClients__contentContainer__textAboveImages}>{textAboveImages}</h4>
          {images.map(({ imagePath, description, style }) => [
            <img
              key={imagePath}
              src={`${pathToImages(imagePath, true)}`}
              alt={description}
              className={otherClientsStyles.otherClients__contentContainer__img}
              style={style}
            />
          ])}
        </div>
      </div>
    </>
  )
}

const pathToImages = require.context('../../images', true);

export default Footer;