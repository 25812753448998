import React from 'react';

import footerStyles from './footer.module.scss';

function Footer({ copyrightText }) {
  return (
    <>
      <footer
        className={footerStyles.footer__copyright}
        dangerouslySetInnerHTML={{
          __html: copyrightText
        }}>
      </footer>
    </>
    
  )
}

export default Footer;