import React from 'react';
import { Link } from 'gatsby';

import imgageTilesStyle from './image-tiles.module.scss';

function ImageTiles({ images }) {
  return (
    <div className={imgageTilesStyle.imageTitles}>
      {
        images.map(({ imagePathDesktop, imagePathMobile, altText, caption, link }) => {
          return (
            <div key={altText} className={imgageTilesStyle.imageTitles__tile}>
              <Link to={link} className={imgageTilesStyle.imageTitles__tile__link}>
                <picture>
                  <source
                    media="(max-width: 600px)"
                    srcSet={require(`../../images${imagePathMobile}`)}
                    size="100vw"
                  />
                  <img
                    srcSet={require(`../../images${imagePathDesktop}`)}
                    alt={altText}
                    className={imgageTilesStyle.imageTitles__tile__img}
                  />
                </picture>
                <p
                  className={imgageTilesStyle.imageTitles__tile__text}
                  dangerouslySetInnerHTML={{
                    __html: caption
                  }}  
                />
              </Link>
              
            </div>
          );
        })
      }
    </div>
  )
}

export default ImageTiles;