import React from 'react';

import duleImageStyles from './dule-image.module.scss';

function DuleImage({ images }) {
  return (
    <div className={duleImageStyles.duleImage}>
      {images.map(({ imagePathDesktop, imagePathMobile, altText }, index) => {
        return (
          <picture key={index}>
            <source
              media="(max-width: 320px)"
              srcSet={require(`../../images${imagePathMobile}`)}
              size="100vw"
            />
            <img
              srcSet={require(`../../images${imagePathDesktop}`)}
              alt={altText}
              className={duleImageStyles.duleImage__image}
            />
          </picture>
        )
      })}
    </div>
  )
}

export default DuleImage;