import React from 'react';

import fullWidthBannerStyles from './full-width-banner.module.scss';

function FullWidthBanner({ imagePathDesktop, imagePathMobile, altText }) {
  return (
    <div className={fullWidthBannerStyles.fullWidthBanner}>
      <picture>
        <source
          srcSet={require(`../../images${imagePathMobile}`)}
          media="(max-width: 420px)"
        />
        <img
          srcSet={require(`../../images${imagePathDesktop}`)}
          alt={altText}
          className={fullWidthBannerStyles.fullWidthBanner__image}
        />
      </picture>
    </div>
    
  )
}

export default FullWidthBanner;